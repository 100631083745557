import { React, useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'
import { ArrayParam, NumberParam, useQueryParam } from 'use-query-params'
import secureLocalStorage from 'react-secure-storage'

import PaginationFooter from '../../components/shared/Pagination/PaginationFooter'
import SearchBar from '../../components/shared/SearchBar'
import SectionHeader from '../../components/shared/SectionHeader'
import { SIDEBAR_BUTTONS } from '../../constants/general'
import OrderGeneratorButton from '../../components/Sales/SalesList/SalesTable/OrderGeneratorButton'
import SalesTable from '../../components/Sales/SalesList/SalesTable/SalesTable'
import SalesFilters from '../../components/Sales/SalesList/SalesFilters/SalesFilters'
import DropDownHeader from '../../components/Sales/SalesList/DropDownHeader'
import { ACTIONS, storeIcon, truckIcon } from '../../constants/icons'
import SalesSelection from '../../components/Sales/SalesList/SelectionBar/SalesSelection'
import TabBar from '../../components/shared/TabBar/TabBar'
import {
  PACKAGE_TYPES,
  CLICK_AND_COLLECT_STATES,
  HOME_DELIVERY_STATES,
  OTHERS_STATUS
} from '../../constants/sales'
import { SERVICES_TYPES } from '../../constants/couriers'
import ExportSales from '../../components/Sales/SalesList/ExportSales'
import { saleStatusColor, setNullNeighbordsValues, mapTicketsSalesMessages } from '../../helpers/sales'
import { getSales } from '../../helpers/request/sales'
import { getStates } from '../../helpers/request/states'
import { getTickets } from '../../helpers/request/tickets'
import { getPersonalization } from '../../helpers/request/personalization'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import ToolTip from '../../components/shared/ToolTip'
import NoAccess from '../../components/NoAccess'
import OriginTabBar from '../../components/Sales/SalesList/OriginTabBar'
import { AuthContext } from '../../contexts/Store'
import { SalesContext } from '../../contexts/SalesContext'
import { notificationContext } from '../../contexts/NotificationContext'
import useFetch from '../../hooks/useFetchParams'

const STORE_SERVICES = ['pick-from-store', 'ship-from-store']
const CD_SERVICES = SERVICES_TYPES.filter(service => !STORE_SERVICES.includes(service))

const Sales = () => {
  const [sales, setSales] = useState([])
  const [cdSales, setCdSales] = useState([])
  const [storeSales, setStoreSales] = useState([])
  const [salesWithError, setSalesWithError] = useState([])
  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [selectedStatus = [], setSelectedStatus] = useQueryParam('status', ArrayParam)
  const [selectedSales, setSelectedSales] = useState([])
  const [selectedToOwnPoint, setSelectedToOwnPoint] = useState([])
  const [page = 1, setPage] = useQueryParam('page', NumberParam)
  const [pageSize = 20, setPageSize] = useQueryParam('pageSize', NumberParam)
  const [isFiltered, setIsFiltered] = useState(false)
  const [packageType = 0, setPackageType] = useQueryParam('packageType', NumberParam)
  const [showArchivedSales, setShowArchivedSales] = useState(false)
  const [filtersData, setFiltersData] = useState({})
  const [appliedFilters, setAppliedFilters] = useState({})
  const [order, setOrder] = useState('')
  const [states, setStates] = useState({})
  const [ticketsData, setTicketsData] = useState({})
  const [salesWithTickets, setSalesWithTickets] = useState({})
  const [isTicketsLoading, setIsTicketsLoading] = useState(true)
  const [personalization, setPersonalization] = useState({})
  const [companyId] = useState(secureLocalStorage.getItem('companiesIds'))
  const [separateSalesByOrigin, setSeparateSalesByOrigin] = useState(false)
  const [selectedOrigin, setSelectedOrigin] = useState('CD')
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [initialDataLoaded, setInitialDataLoaded] = useState(false)

  const abortController = useRef(null)
  const paramsSnapshot = useRef(null)
  const modeChanged = useRef(false)
  const isFetchingSales = useRef(false)

  const { ticketsMessages } = useContext(notificationContext)

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const { setParams, setActualFilteredSales, setPreviousSale,
    setNextSale, setActualSale } = useContext(SalesContext)

  useFetch(getTickets, setTicketsData)

  const fetchStates = useCallback(async () => {
    try {
      const data = await getStates()
      setStates(data)
      return true
    } catch (err) {
      return false
    }
  }, [])

  const fetchPersonalization = useCallback(async () => {
    if (!companyId) return false

    try {
      const data = await getPersonalization({ params: companyId })
      setPersonalization(data)
      return true
    } catch (err) {
      return false
    }
  }, [companyId])

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        await Promise.all([fetchStates(), fetchPersonalization()])
        setInitialDataLoaded(true)
      } catch (err) {
        setError(err)
        setIsLoading(false)
      }
    }

    loadInitialData()
  }, [fetchStates, fetchPersonalization])

  useEffect(() => {
    if (personalization && personalization.separateSalesByOrigin !== undefined) {
      const newValue = personalization.separateSalesByOrigin
      if (newValue !== separateSalesByOrigin) {
        setSeparateSalesByOrigin(newValue)
        modeChanged.current = true
      }
    }
  }, [personalization, separateSalesByOrigin])

  const type = PACKAGE_TYPES[packageType]
  const statusParam = selectedStatus.join(',')

  const baseParams = useMemo(
    () => ({
      archived: showArchivedSales,
      packageType: type,
      page,
      pageSize,
      ...appliedFilters,
      status: statusParam || undefined,
      searchTerm: searchTerm || undefined,
      order: order || undefined,
      getFilters: Object.keys(filtersData).length === 0 || undefined
    }), [
      showArchivedSales, type, page, pageSize, appliedFilters, statusParam,
      searchTerm, order, filtersData
    ]
  )

  const standardParams = useMemo(() => baseParams, [baseParams])

  const cdParams = useMemo(() => ({
    ...baseParams,
    courierService: CD_SERVICES.join(',')
  }), [baseParams])

  const storeParams = useMemo(() => ({
    ...baseParams,
    courierService: STORE_SERVICES.join(',')
  }), [baseParams])

  const salesToShow = useMemo(() => {
    if (!separateSalesByOrigin) {
      return sales
    }
    return selectedOrigin === 'CD' ? cdSales : storeSales
  }, [separateSalesByOrigin, selectedOrigin, sales, cdSales, storeSales])

  const hasParamsChanged = useCallback(() => {
    if (!paramsSnapshot.current) return true

    const currentParams = JSON.stringify({
      baseParams,
      separateSalesByOrigin,
      selectedOrigin,
      page
    })

    const different = currentParams !== paramsSnapshot.current
    return different || modeChanged.current
  }, [baseParams, separateSalesByOrigin, selectedOrigin, page])

  // eslint-disable-next-line
  const fetchSalesData = useCallback(async () => {
    if (!initialDataLoaded) return

    if (isFetchingSales.current) return
    isFetchingSales.current = true

    if (!hasParamsChanged() && !modeChanged.current) {
      setIsLoading(false)
      isFetchingSales.current = false
      return
    }

    paramsSnapshot.current = JSON.stringify({
      baseParams,
      separateSalesByOrigin,
      selectedOrigin,
      page
    })

    modeChanged.current = false
    setIsLoading(true)

    if (abortController.current) {
      abortController.current.abort()
    }

    abortController.current = new AbortController()
    const { signal } = abortController.current

    setError(null)

    try {
      if (separateSalesByOrigin) {
        if (selectedOrigin === 'CD') {
          const cdResponse = await getSales({ params: cdParams, signal })
          if (!signal.aborted) {
            setCdSales(cdResponse.sales || [])
            if (cdResponse.salesWithError?.length) {
              setSalesWithError(cdResponse.salesWithError)
            }
            if (cdResponse.filters) {
              setFiltersData(cdResponse.filters)
            }
          }
        }
        else {
          const storeResponse = await getSales({ params: storeParams, signal })
          if (!signal.aborted) {
            setStoreSales(storeResponse.sales || [])
            if (storeResponse.salesWithError?.length) {
              setSalesWithError(storeResponse.salesWithError)
            }
            if (storeResponse.filters) {
              setFiltersData(storeResponse.filters)
            }
          }
        }
      } else {
        const response = await getSales({ params: standardParams, signal })
        if (!signal.aborted) {
          setSales(response.sales || [])
          if (response.salesWithError?.length) {
            setSalesWithError(response.salesWithError)
          }
          if (response.filters) {
            setFiltersData(response.filters)
          }
        }
      }
    } catch (err) {
      if (!signal.aborted) {
        setError(err)
      }
    } finally {
      if (!signal.aborted) {
        setIsLoading(false)
      }
      isFetchingSales.current = false
    }
  }, [
    hasParamsChanged, baseParams, separateSalesByOrigin, initialDataLoaded,
    selectedOrigin, page, standardParams, cdParams, storeParams
  ])

  useEffect(() => {
    if (initialDataLoaded) {
      fetchSalesData()
    }
  }, [fetchSalesData, initialDataLoaded])

  useEffect(() => {
    if (!initialDataLoaded) return

    paramsSnapshot.current = null
    fetchSalesData()
  }, [separateSalesByOrigin, selectedOrigin, fetchSalesData, initialDataLoaded])

  useEffect(
    () => () => {
      if (abortController.current) {
        abortController.current.abort()
      }
    },
    []
  )

  const handleOriginChange = (newOrigin) => {
    if (newOrigin === selectedOrigin) return
    setSelectedOrigin(newOrigin)
    setPage(1)
  }

  useEffect(() => {
    if (ticketsData.length && sales.length) {
      const salesWithTicketsMessages = mapTicketsSalesMessages(ticketsData, ticketsMessages)

      setSalesWithTickets(prevState => {
        if (JSON.stringify(prevState) !== JSON.stringify(salesWithTicketsMessages)) {
          return salesWithTicketsMessages
        }
        return prevState
      })
    }
    setIsTicketsLoading(false)
  }, [ticketsData, sales, ticketsMessages])

  const searchHandler = (newSearchTerm) => {
    setSearchBarInput(newSearchTerm)
    const searchTermList = newSearchTerm.split(',').map(term => term.trim())
    const uncompletedElementExists = searchTermList.some(term => term.length < 3)
    if (newSearchTerm.length === 0 || !uncompletedElementExists) {
      clearTimeout(searchTimeout)
      const newTimeout = setTimeout(() => {
        setPage(1)
        setSearchTerm(newSearchTerm)
      }, 300)
      setSearchTimeout(newTimeout)
    }
  }

  const handleSort = (field, direction) => {
    setPage(1)
    if (direction === 'asc') {
      setOrder(field)
    } else {
      setOrder(`-${field}`)
    }
  }

  const changePackageType = (newType) => {
    setPage(1)
    setFiltersData({})
    setPackageType(newType)
  }

  const changeShowArchivedSales = (newValue) => {
    setPage(1)
    setShowArchivedSales(newValue)
  }

  const changePageSize = (newPageSize) => {
    setPage(1)
    setPageSize(newPageSize)
  }

  const changeAppliedFilters = (newFilters) => {
    setPage(1)
    setAppliedFilters(newFilters)
  }

  const changeSelectedStatus = (newStatus) => {
    setPage(1)
    setSelectedStatus(newStatus)
  }

  const statusFilters = packageType === 0 ? CLICK_AND_COLLECT_STATES : HOME_DELIVERY_STATES

  useEffect(() => {
    let activeParams = standardParams
    if (separateSalesByOrigin) {
      activeParams = selectedOrigin === 'CD' ? cdParams : storeParams
    }

    setParams(activeParams)
    setNullNeighbordsValues(setPreviousSale, setNextSale)
    setActualSale(null)
    setActualFilteredSales((prev) => {
      const filteredSales = salesToShow.filter(sale => sale.status !== 'with_error')
      if (JSON.stringify(prev) !== JSON.stringify(filteredSales)) {
        return filteredSales
      }
      return prev
    })
  }, [standardParams, cdParams, storeParams, separateSalesByOrigin, selectedOrigin,
      salesToShow, setActualFilteredSales, setActualSale,
      setNextSale, setParams, setPreviousSale])

  if (!hasAccess('sales')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  return (
    <div className="relative h-screen flex flex-col bg-light-grey">
      <SectionHeader
        dropdown={
          <DropDownHeader
            title={showArchivedSales ? 'Ventas archivadas' : 'Ventas'}
            icon={showArchivedSales ? ACTIONS.archive : SIDEBAR_BUTTONS.sales.inactiveIcon}
            dropDownItem={
              <div
                className="flex w-max gap-2 p-3"
                onClick={() => changeShowArchivedSales(!showArchivedSales)}
                tabIndex="0"
                role="button"
              >
                <img
                  src={showArchivedSales ? SIDEBAR_BUTTONS.sales.inactiveIcon : ACTIONS.archive}
                  alt="ventas"
                  className="w-4 h-4 m-auto"
                />
                <div>{showArchivedSales ? 'Ventas' : 'Ventas archivadas'}</div>
              </div>
            }
          />
        }
        rightChildren={
          <TabBar tabs={['Propias', 'Multicanal']} tab={packageType} setTab={changePackageType} />
        }
        searchBar={
          <ToolTip
            backgroundColor="bg-ultra-dark-grey"
            textColor="text-white"
            hoverElement={
              <SearchBar
                searchbarInput={searchBarInput}
                searchKeyword={searchHandler}
              />
            }
            right
          >
            <div className="max-w-[13rem]">
              Ahora puedes buscar parámetros que no se encuentren en la tabla
            </div>
          </ToolTip>
        }
      />
      {separateSalesByOrigin && (
        <div className="pt-4 px-10 -mb-4 relative z-10">
          <OriginTabBar
            tabs={['CD', 'Tienda']}
            selectedTab={selectedOrigin}
            setTab={handleOriginChange}
            icons={[truckIcon, storeIcon]}
          />
        </div>
      )}
      <ActionsBar
        statusFilters={showArchivedSales ? undefined : statusFilters}
        extraFilters={showArchivedSales ? undefined : OTHERS_STATUS}
        extraFiltersColor={saleStatusColor}
        selectedFilters={selectedStatus}
        setSelectedFilters={changeSelectedStatus}
        salesWithError={salesWithError}
        rightChildren={
          <div className="flex gap-1">
            {!isLoading && (
              <>
                <SalesFilters
                  filtersData={filtersData}
                  isFiltered={isFiltered}
                  setIsFiltered={setIsFiltered}
                  handleFilterChange={changeAppliedFilters}
                  showArchivedSales={showArchivedSales}
                  packageType={type}
                  selectedFilters={appliedFilters}
                />
                <ExportSales packageType={type} filtersData={filtersData} />
              </>
            )}
            <OrderGeneratorButton />
          </div>
        }
      />
      <SalesTable
        sales={salesToShow}
        selectedSales={selectedSales}
        setSelectedSales={setSelectedSales}
        setSelectedToOwnPoint={setSelectedToOwnPoint}
        pageSize={pageSize}
        page={page}
        isLoading={isLoading || isTicketsLoading || loadingResources}
        showArchivedSales={showArchivedSales}
        packageType={type}
        error={error}
        handleSort={handleSort}
        states={states}
        salesWithTickets={salesWithTickets}
      />
      <PaginationFooter
        page={page}
        pageSize={pageSize}
        pageItems={salesToShow}
        setPage={setPage}
        setPageSize={changePageSize}
        totalItems={salesToShow}
        useCount={false}
      />
      {!showArchivedSales && selectedSales.length > 0 && (
        <div className="absolute bottom-4 left-10">
          <SalesSelection
            selectedSales={selectedSales}
            selectedToOwnPoint={selectedToOwnPoint}
            status={selectedStatus}
            statusFromSelected={salesToShow
              .filter((sale) => selectedSales.includes(sale.idbulto))
              .map((sale) => sale.status)}
            page={page}
            pageSize={pageSize}
            packageType={type}
            handleCloseBar={() => {
              setSelectedSales([])
              setSelectedToOwnPoint([])
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Sales
